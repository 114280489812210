'use client';

import {
  Box,
  Radio,
  Checkbox,
  Accordion,
  FormGroup,
  RadioGroup,
  Typography,
  AccordionDetails,
  FormControlLabel,
  AccordionSummary,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';

import { useRouter } from 'next/navigation';
import { Category } from '@/lib/types/interfaces/Category';
import {
  breakCrumbsHeight, colorVariables, miles, searchResultsHeight, servicesListHeight,
} from '@/constants';
import { getCategories } from '@/lib/api/functions';
import { getIdsFromSlug, getSubCategoryIdQuery } from '@/lib/utils/functions';
import { SearchLayoutContext } from '@/page-components/Search/SearchLayout/SearchLayout';
import { SubCategoriesState } from '@/lib/types/interfaces/SubCategoriesState';
import { useQuery } from '@/hooks/useQuery';

interface Props {
  children?: React.ReactNode;
  categories: Category[]
}

const subCategoriesInitialState: SubCategoriesState = {
  parentId: null,
  list: [],
  checkbox: {},
};

const Filters = ({ children, categories }: Props) => {
  const route = useRouter();
  const { queryParams } = useQuery();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedMiles, setSelectedMiles] = useState(miles.find(mile => mile === queryParams.get('radius')) || miles[0]);
  const [isSelectedMilesVisible, setIsSelectedMilesVisible] = useState(false);
  const {
    term,
    location,
    currentCategory,
    setSubCategories,
    subCategoriesState,
    setCurrentCategory,
  } = useContext(SearchLayoutContext);
  const [category, setCategory] = useState<null | Category>(currentCategory || null);

  const handleChange = (value: string) => {
    const categoryValue = categories?.find((item) => item.slug === value);
    setCategory(categoryValue!);
    setSubCategories(subCategoriesInitialState);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    queryParams.delete('page');
    setSubCategories((prevState: SubCategoriesState) => ({
      ...prevState,
      checkbox: {
        ...prevState.checkbox,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const handleQueryFilters = useCallback(() => {
    const newParams = new URLSearchParams(queryParams.toString());

    const keysToRemove: string[] = [];
    const checkedValues = getIdsFromSlug(
      subCategoriesState.list,
      Object.keys(subCategoriesState.checkbox).filter(item => subCategoriesState.checkbox[item]),
    );
    newParams.forEach((_, key) => {
      if (key.startsWith('subCategory')) {
        keysToRemove.push(key);
      }
    });
    keysToRemove.forEach(key => newParams.delete(key));
    checkedValues.forEach(value => newParams.append('subCategory', String(value)));

    if (location && newParams.has('loc')) {
      newParams.delete('loc');
      newParams.append('loc', String(location.id));
    }

    if (term && newParams.has('term')) {
      newParams.delete('term');
      newParams.append('term', term);
    } else {
      newParams.delete('term');
    }

    if ((selectedMiles || newParams.has('radius')) && newParams.has('loc')) {
      newParams.delete('radius');
      newParams.append('radius', selectedMiles);
    } else {
      newParams.delete('radius');
    }

    newParams.delete('page');

    const queryString = newParams.toString();
    if (category?.slug && !checkedValues.length) {
      route.push(`/hire/${category?.slug}${queryString ? `?${queryString}` : ''}`);
      return;
    }
    if (queryString && category?.slug) {
      route.push(`/hire/${category?.slug}?${queryString}`);
    }
  }, [subCategoriesState.checkbox, queryParams]);

  const fetchSubCategories = async (filter: Category) => {
    if (filter.id !== subCategoriesState.parentId) {
      try {
        return await getCategories([filter.id]);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleNewCategory = (newCategory: Category) => {
    queryParams.toString();
    !term && queryParams.delete('term');
    !location && queryParams.delete('loc');

    queryParams.delete('page');
    const query = queryParams.toString();
    route.push(`/hire/${newCategory?.slug}${query ? `?${query}` : ''}`);

    setCategory((prevState) => {
      if (prevState?.id === newCategory.id) {
        setIsExpanded(!isExpanded);
        return prevState;
      }
      setIsExpanded(true);
      setSubCategories(subCategoriesInitialState);
      return newCategory;
    });
  };

  const renderFilterData = useMemo(() => categories?.map(filter => (
    <Accordion
      sx={{ boxShadow: 'unset', position: 'unset', wordBreak: 'break-word' }}
      expanded={filter.id === subCategoriesState?.parentId && isExpanded}
      key={filter.id}
      disableGutters
      onChange={() => {
        handleNewCategory(filter);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='panel1-content'
        id='panel1-header'
        sx={{
          minHeight: '42px',
          '& .MuiAccordionSummary-content': {
            marginTop: 0,
            marginBottom: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            visibility: category?.id === filter.id && !subCategoriesState.list.length ? 'hidden' : 'visible',
          },
        }}
      >
        <FormControlLabel
          value={filter.slug}
          control={<Radio />}
          label=''
        />
        <Typography
          variant='body1'
          fontWeight={400}
          display='flex'
          alignItems='center'
          fontSize={14}
        >
          {filter.name}
        </Typography>
      </AccordionSummary>

      {filter.id === subCategoriesState?.parentId && !!subCategoriesState?.list?.length && (
        <AccordionDetails
          sx={{
            padding: '8px 16px 16px',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          {category?.id === subCategoriesState.parentId && (subCategoriesState.list.map(subcategory => (
            <Box
              key={`subcategory-${subcategory.id}`}
              marginTop={{ lg: 1 }}
              sx={{
                marginLeft: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={subCategoriesState.checkbox[subcategory.slug]}
                    onChange={handleCheckboxChange}
                    name={subcategory.slug}
                    id={String(subcategory.id)}
                    sx={{ fontSize: 14, padding: '0 9px' }}
                  />
                )}
                label={subcategory.name}
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: 14,
                  },
                }}
              />
            </Box>
          )) || null)}
        </AccordionDetails>
      )}
    </Accordion>
  )), [subCategoriesState, categories, isExpanded, location]);

  const fetchData = useCallback(async () => {
    if (category) {
      const query = queryParams.toString();
      const subCategoriesResponse = await fetchSubCategories(category);

      if (subCategoriesResponse?.data?.length && category?.id) {
        const idList = getSubCategoryIdQuery(query);
        const subCategoryList = subCategoriesResponse.data?.filter(item => idList.includes(item.id)) || [];
        const newCheckbox = Object.fromEntries(subCategoryList.map(item => [item.slug, true]));

        setSubCategories(() => ({
          parentId: category.id,
          list: subCategoriesResponse.data,
          checkbox: {
            ...Object.fromEntries(subCategoriesResponse.data.map(item => [item.slug, false])),
            ...(subCategoryList?.length && newCheckbox),
          },
        }));
      }
    }
  }, [category?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    handleQueryFilters();
  }, [subCategoriesState.checkbox]);

  useEffect(() => {
    if (currentCategory) {
      setCategory(currentCategory);
      setIsExpanded(true);
    } else {
      setCategory(null);
      setCurrentCategory(null);
      setIsExpanded(false);
    }
  }, [currentCategory]);

  useEffect(() => {
    if (queryParams.has('loc')) {
      setIsSelectedMilesVisible(true);
    } else {
      setIsSelectedMilesVisible(false);
    }
  }, [queryParams.get('loc')]);

  useEffect(() => {
    if (queryParams.has('radius')) {
      setSelectedMiles(queryParams.get('radius') || miles[0]);
    }
  }, [queryParams.get('radius')]);

  const handleMilesChange = async (e: SelectChangeEvent) => {
    const newRadius = e.target.value;
    setSelectedMiles(newRadius);

    const newSearchParams = new URLSearchParams(queryParams.toString());
    newSearchParams.set('radius', newRadius);

    if (category) {
      route.push(`/hire/${category?.slug}?${newSearchParams.toString()}`);
    } else {
      route.push(`/hire?${newSearchParams.toString()}`);
    }
  };

  return (
    <Box
      sx={{
        padding: '16px 0 16px 16px',
        borderLeft: `1px solid ${colorVariables.separatorColor}`,
        maxHeight: searchResultsHeight + servicesListHeight + breakCrumbsHeight,
        overflow: 'auto',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Typography
          variant='body1'
          fontWeight={700}
        >
          Filters
        </Typography>
        {children}
      </Box>

      {isSelectedMilesVisible && (
        <Box
          display='flex'
          alignItems='center'
          mr={1}
          mt={1}
          sx={{ gap: '12px' }}
        >
          <Typography
            fontSize={14}
            sx={{ textWrap: 'nowrap' }}
          >
            Results within
          </Typography>
          <Select
            labelId='miles-label'
            id='miles'
            value={selectedMiles}
            onChange={handleMilesChange}
            sx={{
              fontSize: 14,
              '.MuiSelect-select': {
                padding: '10px 32px 10px 14px',
              },
            }}
          >
            {miles.map((mile) => (
              <MenuItem
                key={mile}
                value={mile}
                sx={{
                  fontSize: 14,
                }}
              >
                {`${mile} miles`}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      <Typography
        variant='body1'
        fontWeight={400}
        marginTop={1}
      >
        Service Category
      </Typography>

      <Box
        marginTop={2}
      >
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue=''
          name='radio-buttons-group'
          value={category?.slug || ''}
          onChange={(e) => handleChange(e.target.value)}
        >
          {renderFilterData}
        </RadioGroup>
      </Box>

      <FormGroup />
      <Box />
    </Box>
  );
};

export default Filters;

'use client';

import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { sendGTMEvent } from '@next/third-parties/google';

import theme from '@/theme';
import Filters from '@/page-components/Search/Filters';
import { colorVariables, miles } from '@/constants';
import {
  getCategories,
  getGeoCity,
  getHire,
  getRandomListing,
} from '@/lib/api/functions';
import { Category } from '@/lib/types/interfaces/Category';
import { City } from '@/lib/types/interfaces/City';
import { SubCategoriesState } from '@/lib/types/interfaces/SubCategoriesState';
import { SearchContext } from '@/lib/types/interfaces/SearchContext';
import { SearchFormFields } from '@/lib/types/interfaces/SearchFormFields';
import SearchHeader from '../SearchHeader';
import Footer from '@/components/Footer';

const subCategoriesInitialState: SubCategoriesState = {
  parentId: null,
  list: [],
  checkbox: {},
};
export const SearchLayoutContext = createContext<SearchContext>({
  term: null,
  location: null,
  categories: [],
  currentCategory: null,
  setSubCategories: () => {},
  setCurrentCategory: () => {},
  fetchLocation: async () => {},
  subCategoriesState: subCategoriesInitialState,
});

const SearchLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const path = usePathname();
  const router = useRouter();
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategoriesState, setSubCategories] = useState<SubCategoriesState>(subCategoriesInitialState);
  const [location, setLocation] = useState<City | null>(null);
  const searchParams = useSearchParams();
  const queryParams = new URLSearchParams(searchParams.toString());
  const route = useRouter();
  const [term, setTermName] = useState<string | null>(queryParams.get('term')?.toString() || '');
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const currentCategoryPath = path.split('/')[2];

  const fetchLocation = useCallback(async (locationId: string) => {
    if (locationId) {
      const locationResponse = await getGeoCity(locationId);
      setLocation(locationResponse?.data || null);
    }
  }, [queryParams.has('loc')]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);

      if (currentCategoryPath) {
        const category = response.data.find(item => item.slug === currentCategoryPath) || null;
        setCurrentCategory(category);
      }
      if (queryParams.has('loc') && queryParams?.get('loc')?.toString()) {
        await fetchLocation(queryParams.get('loc')!.toString());
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleClearFilters = () => {
    route.push('/hire');
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (queryParams.has('loc') && queryParams?.get('loc')?.toString()) {
      fetchLocation(queryParams.get('loc')!.toString());
    }

    if (!queryParams.has('loc') && location?.id) {
      setLocation(null);
    }
  }, [queryParams.get('loc')]);

  useEffect(() => {
    setTermName(queryParams.get('term')?.toString() || '');
  }, [queryParams.get('term')]);

  useEffect(() => {
    if (categories.length) {
      const category = categories.find(item => item.slug === currentCategoryPath) || null;
      setCurrentCategory(category);
    }
  }, [currentCategoryPath]);

  return (
    <SearchLayoutContext.Provider value={useMemo(() => ({
      term,
      location,
      categories,
      fetchLocation,
      currentCategory,
      setSubCategories,
      subCategoriesState,
      setCurrentCategory,
    }), [currentCategory, subCategoriesState, location, term, fetchLocation])}
    >
      <Formik
        initialValues={{
          location,
          business: term || '',
        }}
        onSubmit={async (values: SearchFormFields) => {
          values?.location?.id ? queryParams.set('loc', String(values?.location?.id)) : queryParams.delete('loc');
          values.business ? queryParams.set('term', String(values.business)) : queryParams.delete('term');
          values?.location?.id ? queryParams.set('radius', queryParams.get('radius') || miles[0]) : queryParams.delete('radius');
          queryParams.delete('page');
          const queryPath = queryParams.toString() ? `?${queryParams.toString()}` : '';

          let subCategoriesIds = queryParams.getAll('subCategory');
          let subCategorySlug = '';

          if (subCategoriesIds?.length && currentCategory) {
            try {
              const response = await getCategories([currentCategory?.id]);
              subCategorySlug = response.data
                .filter((item) => subCategoriesIds
                  .includes(String(item.id)))
                .map(item => item.name).join();
            } catch (err) {
              console.error(err);
            }
          }

          const locationName = location?.name || '';
          const stateName = location?.state || '';

          let searchData;

          try {
            const isQuery = queryParams.size || currentCategory;
            const params = {
              ...(currentCategory ? { cat: String(currentCategory?.id) } : {}),
              ...(Object.fromEntries(queryParams)),
            };
            searchData = !isQuery ? await getRandomListing() : await getHire(params);
          } catch (error: any) {
            console.error(error);
          }

          sendGTMEvent({
            event: 'view_search_results',
            from: 'Search page',
            search_term: values.business || '',
            search_location: locationName && stateName ? `${locationName}, ${stateName}` : '',
            search_category: currentCategory?.name || '',
            subCategories: subCategorySlug || '',
            number_of_results: searchData?.count || searchData?.data?.length || 0,
            radius: values?.location?.id ? queryParams.get('radius') || miles[0] : '',
          });
          router.push(`${path}${queryPath}`);
        }}
      >
        {({ setFieldValue }) => (
          <>
            <SearchHeader />
            <main>
              <Grid
                container
                spacing={0}
              >
                {isDesktop && (
                  <Grid
                    item
                    lg={2}
                  >
                    <Filters categories={categories} />
                    <Box
                      padding={3}
                      position='sticky'
                      bottom={0}
                      bgcolor='white'
                    >
                      <Button
                        variant='outlined'
                        color='success'
                        fullWidth
                        onClick={async () => {
                          await setFieldValue('location', '');
                          await setFieldValue('business', '');
                          handleClearFilters();
                        }}
                      >
                        Clear Filters
                      </Button>
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  lg={10}
                  md={12}
                  xs={12}
                  sx={{
                    borderLeft: `1px solid ${colorVariables.separatorColor}`,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {children}
                </Grid>
              </Grid>
            </main>
            <Footer />
          </>
        )}
      </Formik>
    </SearchLayoutContext.Provider>
  );
};
export default SearchLayout;

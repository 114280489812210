import { sendGTMEvent } from '@next/third-parties/google';

export const getIdsFromNames = <T extends { id: number, name: string }>(categories: T[], names: string[]): number[] => {
  const ids: number[] = [];
  names.forEach((name) => {
    const category = categories.find((cat) => cat.name === name);
    if (category) {
      ids.push(category.id);
    }
  });
  return ids;
};

export const getIdsFromSlug = <T extends { id: number, slug: string }>(categories: T[], names: string[]): number[] => {
  const ids: number[] = [];
  names.forEach((slug) => {
    const category = categories.find((cat) => cat.slug === slug);
    if (category) {
      ids.push(category.id);
    }
  });
  return ids;
};

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const arrayToQueryParam = (key: string, values: string[] | number[]): string => (
  values.map(value => `${key}[]=${encodeURIComponent(value)}`).join('&')
);

export const getSubCategoryIdQuery = (queryString: string): number[] => {
  const regex = /subCategory=(\d+)/g;

  return Array.from(queryString.matchAll(regex), match => parseInt(match[1], 10));
};

export const isMacintosh = () => navigator?.platform?.indexOf('Mac') > -1 || null;

export const getStateAndCityFromPath = (url: string): string => {
  const regex = /\/([A-Z]{2})(?:\/([A-Za-z_]+))?/;
  const match = url.match(regex);

  return match ? `/${match[1]}${match[2] ? `/${match[2]}` : ''}` : '';
};

export const handleGTMEvent = (url: string, event: string) => {
  sendGTMEvent({
    event,
    url,
  });
};

'use client';

import { Roboto } from 'next/font/google';
import { green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const theme = createTheme({
  typography: {
    fontFamily: roboto.style.fontFamily,
  },
  palette: {
    primary: green,
  },
  shape: {
    borderRadius: 28,
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          label: {
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

export default theme;

import { useEffect, DependencyList } from 'react';
import useTimeout from '@/hooks/useTimeout';

export default function useDebounce(callback: () => void, delay: number, dependencies: DependencyList): void {
  const { reset, clear } = useTimeout(callback, delay);

  useEffect(() => {
    reset();
  }, [...dependencies, reset]);

  useEffect(() => clear, []);
}

import {
  Drawer, Box,
  TextField, InputAdornment,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import {
  MouseEvent,
  useContext, useEffect, useMemo, useState,
} from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { sendGTMEvent } from '@next/third-parties/google';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useFormik } from 'formik';
import { colorVariables, headerControlsHeight, miles } from '@/constants';
import DrawerProps from '@/lib/types/interfaces/Drawer';
import { LocationSearch } from '@/lib/types/interfaces/LocationSearch';
import useDebounce from '@/hooks/useDebounce';
import {
  getCategories,
  getHire,
  getLocationSearch,
  getRandomListing,
} from '@/lib/api/functions';
import { SearchLayoutContext } from '@/page-components/Search/SearchLayout/SearchLayout';
import theme from '@/theme';
import { ServicesLayoutContext } from '@/page-components/Services/ServicesLayout/ServicesLayout';

const SearchDrawer = ({ open, toggleDrawer }: DrawerProps) => {
  const route = useRouter();
  const path = usePathname();
  const isMobileSearchPlaceholder = useMediaQuery(theme.breakpoints.down(325));
  const isSearch = path.startsWith('/hire');
  // @ts-ignore
  const { location, term, currentCategory } = useContext(isSearch ? SearchLayoutContext : ServicesLayoutContext);
  const [currentLocation, setCurrentLocation] = useState<null | LocationSearch>(null);
  const [locations, setLocations] = useState<LocationSearch[]>([]);
  const searchParams = useSearchParams();
  const queryParams = new URLSearchParams(searchParams.toString());

  const formik = useFormik<{
    location: LocationSearch | null,
    business: string
  }>({
    initialValues: {
      location: null,
      business: term || '',
    },
    onSubmit: async (values) => {
      if (values.business) {
        queryParams.set('term', values.business);
      } else {
        queryParams.delete('term');
      }

      if (values.location) {
        queryParams.set('loc', String(currentLocation?.id || location?.id));
        queryParams.set('radius', queryParams.get('radius') || miles[0]);
      } else {
        queryParams.delete('loc');
        queryParams.delete('radius');
      }

      let category = currentCategory ? `/${currentCategory.slug}` : '';
      let subCategorySlug = '';
      let subCategoryId;
      const pathItems = path.split('/');

      if (currentCategory?.slug && !isSearch) {
        const categoryIndex = pathItems.findIndex(item => item === currentCategory.slug);

        if (pathItems[categoryIndex + 1] && pathItems[categoryIndex + 1]?.length !== 2) {
          subCategorySlug = pathItems[categoryIndex + 1];

          try {
            const response = await getCategories([currentCategory?.id]);
            subCategoryId = response.data.find((item) => item.slug === subCategorySlug)?.id;
            subCategoryId && queryParams.set('subCategory', String(subCategoryId));
          } catch (err) {
            console.error(err);
          }
        }
      }

      let subCategoriesIds = queryParams.getAll('subCategory');

      if (isSearch && subCategoriesIds?.length && currentCategory) {
        try {
          const response = await getCategories([currentCategory?.id]);
          subCategorySlug = response.data
            .filter((item) => subCategoriesIds
              .includes(String(item.id)))
            .map(item => item.name).join();
        } catch (err) {
          console.error(err);
        }
      }

      let searchData;

      try {
        const isQuery = queryParams.size || currentCategory;
        const params = {
          ...(currentCategory ? { cat: String(currentCategory?.id) } : {}),
          ...(Object.fromEntries(queryParams)),
        };

        searchData = !isQuery ? await getRandomListing() : await getHire(params);
      } catch (error: any) {
        console.error(error);
      }

      const locationName = currentLocation?.name || location?.name || '';
      const stateName = currentLocation?.state || location?.state || '';
      const radius = values?.location ? queryParams.get('radius') || miles[0] : '';

      if (!isSearch) {
        sendGTMEvent({
          event: 'view_search_results',
          from: 'Seo page',
          search_term: values.business || '',
          search_location: locationName && stateName ? `${locationName}, ${stateName}` : '',
          search_category: currentCategory?.name || '',
          subCategories: subCategorySlug || '',
          number_of_results: searchData?.count || searchData?.data?.length || 0,
          radius,
        });
      } else {
        sendGTMEvent({
          event: 'view_search_results',
          from: 'Search page',
          search_term: values.business || '',
          search_location: locationName && stateName ? `${locationName}, ${stateName}` : '',
          search_category: currentCategory?.name || '',
          subCategories: subCategorySlug || '',
          number_of_results: searchData?.count || searchData?.data?.length || 0,
          radius,
        });
      }

      route.push(`/hire${category}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`);
      toggleDrawer(false);
    },
  });

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleDrawer(false);
  };

  useEffect(() => {
    formik.setFieldValue('location', location?.name || location || '');
  }, [location]);

  useDebounce(async () => {
    const isLocation = locations.some((item) => item.name === String(formik.values.location));
    if (formik.values.location && !isLocation) {
      const locationsData = await getLocationSearch(String(formik.values.location));
      setLocations(locationsData?.data || []);
      return;
    }
    setLocations([]);
  }, 300, [formik.values.location]);

  const renderLocationData = useMemo(() => locations.map((item) => (
    <Box
      key={item.id}
      paddingY={2}
      borderBottom={`1px solid ${colorVariables.separatorColor}`}
      onClick={() => {
        formik.setFieldValue('location', `${item.name}, ${item.state}`);
        setCurrentLocation(item);
      }}
    >
      <Typography
        variant='body1'
        fontWeight={400}
      >
        {`${item.name}, ${item.state}`}
      </Typography>
    </Box>
  )), [locations]);

  return (
    <Drawer
      open={open}
      anchor='top'
      onClose={() => toggleDrawer(false)}
      sx={{
        '& .MuiDrawer-paper': {
          height: '100%',
        },
      }}
    >
      <Box
        paddingX={2}
        paddingY={2}
        borderBottom={`1px solid ${colorVariables.separatorColor}`}
      >
        <TextField
          label={isMobileSearchPlaceholder ? 'How can we help?' : 'What do you need help with?'}
          variant='outlined'
          value={formik.values.business}
          InputProps={{
            style: {
              height: headerControlsHeight,
            },
            onChange: (event) => {
              formik.setFieldValue('business', event.target.value);
            },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <TextField
          variant='outlined'
          label='Location'
          value={formik.values.location}
          InputProps={{
            style: {
              height: headerControlsHeight,
            },
            onChange: (event) => {
              formik.setFieldValue('location', event.target.value);
            },
            startAdornment: (
              <InputAdornment position='start'>
                <PlaceOutlinedIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{
            marginTop: 2,
            header: headerControlsHeight,
          }}
        />
      </Box>

      <Box
        overflow='scroll'
        paddingX={3}
        paddingY={1}
      >
        {renderLocationData}
      </Box>

      <Box
        paddingX={2}
        paddingY={2}
        display='flex'
        flexDirection='column'
        marginTop='auto'
        gap={2}
        onClick={() => formik.handleSubmit()}
      >
        <Button
          variant='contained'
          color='success'
          sx={{
            minHeight: 56,
          }}
        >
          Search
        </Button>
        <Button
          variant='outlined'
          color='success'
          onClick={handleCancel}
          sx={{
            minHeight: 56,
          }}
        >
          Cancel
        </Button>
      </Box>
    </Drawer>
  );
};

export default SearchDrawer;

import { useSearchParams } from 'next/navigation';

export const useQuery = () => {
  const searchParams = useSearchParams();
  const stringSearchParams = searchParams.toString();
  const queryParams = new URLSearchParams(stringSearchParams);

  return {
    searchParams, stringSearchParams, queryParams,
  };
};

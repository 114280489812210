'use client';

import React from 'react';
import Image from 'next/image';

type PictureProps = {
  src: string,
  alt?: string,
  className?: string,
  width?: number,
  height?: number,
  style?: { [prop: string]: string },
  withPreloader?: boolean,
  preloader?: string,
  onLoad?: () => void,
};
const Img = ({
  src,
  alt = '',
  className,
  width,
  height,
  style,
  withPreloader = false,
  preloader,
  onLoad,
}: PictureProps) => (
  <Image
    src={src}
    alt={alt}
    fill={!width}
    width={width}
    height={height}
    className={className}
    style={style}
    onLoad={onLoad}
    placeholder={withPreloader ? 'blur' : undefined} // eslint-disable-next-line max-len
    blurDataURL={preloader || 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEPERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/2wBDAQUFBQcGBw4ICA4eFBEUHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCAAKAAoDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABgQH/8QAIBAAAgIBAwUAAAAAAAAAAAAAAQIDEQASEyEEFEFhof/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oADAMBAAIRAxEAPwDXJJITFvqSgdLbULqvPzLUg5LKjSue2ha2JsR8HFUVxs2vp11GtscXmZDk7MJ5ACQAxoX7xC//2Q=='}
  />
);

export default Img;
